import { GenericObject } from "../types";

export default function styleToObj(styleStr: string): Object {
    let result: GenericObject = {};
    styleStr.split(';').forEach((styleEl) => {
        const prop = styleEl.split(':');
        let label = '';
        (prop[0].trim()).split('-').forEach((labelSplit, idx) => {
            const piece = idx > 0 ? labelSplit.charAt(0).toUpperCase() + labelSplit.slice(1) : labelSplit;
            label += piece;
        })
        result[label] = prop[1].trim();
    });

    return result;
}
