import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { InputSwitch } from '../../components/inputSwitch/InputSwitch';

import { selectConfig } from '../config/configSlice';

import { selectEnable, setEnable } from './hotspotSwitchSlice';

type Props = {
    className?: string,
    text?:string
}

export function HotspotSwitch({ className, text }: Props) {
    const dispatch = useAppDispatch();
    const conf = useAppSelector(selectConfig);
    const enable = useAppSelector(selectEnable);

    const switchChanged = (value:boolean, element:any) => {
        dispatch(setEnable(value));
    }

    useEffect(() => {
        dispatch(setEnable(!!conf?.showHotspotOnStart));
    }, [conf, dispatch]);

    if (!conf) {
        return null;
    }

    return (
        <InputSwitch className={className} text={text} checked={enable} onChange={switchChanged} />
    );
}