
import styles from './InputSwitch.module.scss';

type Props = {
    className?: string,
    text?: string,
    onChange?: Function,
    checked?: boolean
}

export function InputSwitch({ className, text, onChange, checked }: Props) {
    const handleOnChange = (evt:any) => {
        if (typeof onChange === 'function') {
            const target = evt.currentTarget;
            onChange(target.checked, target);
        }
    }
    const id = 'input-switch-' + Math.random();

    return (
        <div className={["form-check form-switch", styles.root, className].join(' ')}>
            { text && <label className="form-check-label" htmlFor={id}>{ text }</label> }
            <input className="form-check-input" type="checkbox" id={id} onChange={handleOnChange} checked={checked} />
        </div>
    );
}
