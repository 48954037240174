
import React from 'react';

type Props = {
    className?: string,
    id?:string,
    icon?: string,
    text?: string,
    onClick?: Function,
    dataAttrName?: Array<string>,
    dataAttrValue?: Array<string>,
}

const ButtonIcon = React.forwardRef<HTMLButtonElement, Props>(({ className, text, onClick, dataAttrName, dataAttrValue, id }: Props, ref) => {
    const dataAttribs:Record<string, any> = {};
    dataAttrName?.forEach((name, idx) => {
        const key = `data-${name}`;
        const value = dataAttrValue && dataAttrValue.length > idx ? dataAttrValue[idx] : null;
        dataAttribs[key] = value;
    });

    const handleClick = (evt:any) => {
        if (typeof onClick === 'function') {
            onClick(evt);
        }
    }

    return (
        <button ref={ref} className={ ['wsv-btn', className].join(' ') } onClick={handleClick} {...dataAttribs} id={id}>
              <span className='wsv-btn-icon'></span>
              { text }
        </button>
    );
  });

export default ButtonIcon;  

/*export function ButtonIcon({ className, text, onClick, dataAttrName, dataAttrValue, id, ref }: Props) {
    const dataAttribs:Record<string, any> = {};
    dataAttrName?.forEach((name, idx) => {
        const key = `data-${name}`;
        const value = dataAttrValue && dataAttrValue.length > idx ? dataAttrValue[idx] : null;
        dataAttribs[key] = value;
    });

    const handleClick = (evt:any) => {
        if (typeof onClick === 'function') {
            onClick(evt);
        }
    }

    return (
        <button ref={ref} className={ ['wsv-btn', className].join(' ') } onClick={handleClick} {...dataAttribs} id={id}>
              <span className='wsv-btn-icon'></span>
              { text }
        </button>
    );
}*/
