import React, { useState } from 'react';

import getDevice from '../../utils/getDevice';
import fileCheck from '../../utils/fileCheck';

import styles from './Info.module.scss';

type Props = {
    glb?: string,
    usdz?: string,
    mvProps?: Array<Object>
    foot?: string,
}

export function Info({ glb, usdz, mvProps, foot }: Props) {
    const device = getDevice();
    const [show, setShow] = useState(false);

    const printFileLine = (fileUrl:string) => {
        const result = fileCheck(fileUrl);
        if (result.data) {                            
            return <li key={result.data.responseURL} className={ !result.found || result.auth ? styles.danger : '' }>
                <span>{ result.data.responseURL }</span> | <span>found:</span> { '' + result.found } / <span>auth</span>: { '' + (device.isIosSafari ? 'unknown' : result.auth) }                
            </li>; //safari ios fa sempre ovverride dell'header Authorization
        }
        return null;
    };
    const printFileCheck = () => {
        const list:Array<any> = [];
        const files = [glb, usdz];

        files.forEach((file) => {
            if (file) {
                const line = printFileLine(file);
                if (line) {
                    list.push(line);
                }
            }
        });

        return <ul>
            { list.map(item => item) }
        </ul>
    };
    const toggleShow = () => {
        setShow(!show);
    }

    /*if (!props.enable) {
        return null;
    }*/

    return (
        <div className={[styles.root, (show ? styles.show : '')].join(' ')}>
            <div className={styles.head}>
                <button onClick={toggleShow}>{ show ? <i className={styles['gg-close']}></i> : <i className={[styles.iconInfo, 'wsv-btn-info'].join(' ')}></i> }</button>
            </div>
            <div className={styles.body}>
                <h6 className='mt-3 mb-3'>Device Attributes</h6>
                <ul>
                    { Object.keys(device).map((key, idx) => <li key={'li-' + idx}><span>{key}:</span> {device[key] + ''}</li>) }
                    { glb && <li><span>glb:</span> { glb }</li>}
                    { usdz && <li><span>usdz:</span> { usdz }</li>}
                </ul>
                <h6 className='mt-3 mb-3'>File Check</h6>
                { printFileCheck() }
                { mvProps && <h6 className='mt-3 mb-3'>Modelviewer Attributes</h6> }
                { mvProps && <ul>{ mvProps.map((prop:any, idx:number) => <li key={'attr-' + idx}><span>{prop.key}:</span> { '' + prop.value }</li>) }</ul> }
            </div>
            { !show && foot && <div className={styles.foot}>{ foot }</div> }
        </div>
    );
}