//https://github.com/sindresorhus/screenfull

import React, { useState, useEffect } from 'react';

import screenfull from 'screenfull';

import ButtonIcon from '../buttonIcon/ButtonIcon';

type Props = {
    target: any,
    startFullscreen?: boolean,
}

export function FullScreenBtn({ target, startFullscreen }: Props) {
    const [isFull, setIsFull] = useState(false);

    const fullScreen = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle(typeof target === 'function' ? target() : target);
            setIsFull(!isFull);
        }
    }

    const onScreenChange = () => {
        setIsFull(screenfull.isFullscreen);
    }

    useEffect(() => {
        if (screenfull.isEnabled) {
            screenfull.on('change', onScreenChange);
        }
        return () => {
            if (screenfull.isEnabled) {
                screenfull.off('change', onScreenChange);
            }
        }
    });

    return (
        <ButtonIcon className={['wsv-ui-btn wsv-fullscreen-btn', isFull ? 'wsv-fullscreen-on' : ''].join(' ')} onClick={fullScreen} />
    );
}

export default FullScreenBtn;