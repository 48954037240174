import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState/*, AppThunk*/ } from '../../app/store';

export interface HotspotLabelViewerState {
    selected: string;
}

const initialState: HotspotLabelViewerState = {
    selected: "",
};

export const hotspotLabelViewerSlice = createSlice({
    name: 'hotspotLabelViewer',
    initialState,
    reducers: {
        setLabelSelected: (state, action:PayloadAction<string>) => {
            state.selected = action.payload;
        }
    }
});

export const { setLabelSelected } = hotspotLabelViewerSlice.actions;

export const selectLabelSelected = (state: RootState) => state.hotspotLabelViewer.selected;

export default hotspotLabelViewerSlice.reducer;