import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useAppSelector } from '../../app/hooks';

import '@google/model-viewer/dist/model-viewer';

import { GenericObject, Hotspot, AnimationSet, CameraView } from '../../types';

import ButtonIcon from '../buttonIcon/ButtonIcon';

import { selectEnable } from '../../features/hotspotSwitch/hotspotSwitchSlice';

import baseRadiants from './utils/baseRadiants';
import getOrbitValues from './utils/getOrbitValues';
import styleToObj from '../../utils/styleToObj';

import { Poster } from './components/poster/Poster';

import styles from './ModelViewer.module.scss';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['model-viewer']: any;
    }
  }
}

type Props = {
  attributes?: GenericObject,
  hotspots?: Array<Hotspot>,
  iosSrc?: string,
  src?: string,
  showArBtn?: boolean,
  arBtnText?: string,
  //arBtnTextAlt?: string,
  startArOnInit?: boolean,
  onArFail?: Function,
  //onArForceFail?: Function,
  onInit?: Function,
  onLoad?: Function,
  onArLoadStart?: Function,
  onArLoadEnd?: Function,
  onAnimationEnd?: Function,
  onHotspotClick?: Function,
  onHotspotMouseEnter?: Function,
  onHotspotMouseLeave?: Function,
  onCameraChange?: Function,
  showButton?: boolean,
  showModel?: boolean,
  variant?: string,
  animation?: string,
  animationProps?: AnimationSet,
  cameraView?: CameraView,
  poster?: string,
  posterLabel?: string | undefined,
}

export function ModelViewer({
  attributes,
  hotspots,
  iosSrc,
  src,
  showArBtn,
  onArFail,
  //onArForceFail,
  onInit,
  onArLoadStart,
  onArLoadEnd,
  onLoad,
  onAnimationEnd,
  onHotspotClick,
  onHotspotMouseEnter,
  onHotspotMouseLeave,
  onCameraChange,
  showButton,
  showModel,
  arBtnText,
  startArOnInit,
  variant,
  animation,
  animationProps,
  cameraView,
  poster,
  posterLabel,
}: Props) {
  const mvRef = useRef<any>(null);
  const enableHotspot = useAppSelector(selectEnable);
  const [loaded, setLoaded] = useState(false);

  //conversione style in object
  //remove attributes set to false
  const attribsCopy: GenericObject = {};
  if (attributes) {
    Object.keys(attributes).forEach((key) => {
      if (key === "style" && typeof attributes[key] === 'string') {
        attribsCopy[key] = styleToObj(attributes[key]);
      } else if (attributes[key] !== false) {
        attribsCopy[key] = attributes[key];
      }
    });
  }

  //Functions

  const openAr = useCallback((/*forced?:boolean*/) => {
    if (mvRef.current && mvRef.current.canActivateAR) {
      mvRef.current.activateAR();
      if (typeof onArLoadStart === 'function') {
        onArLoadStart();
      }
    } else if (typeof onArFail === 'function') {
      onArFail();
    }
    /*else {
      if (forced && typeof onArForceFail === 'function') {
        onArForceFail();
      } else if (typeof onArFail === 'function') {
        onArFail();
      }
    }*/
  }, [/*canAr,*/ mvRef, onArLoadStart, onArFail/*, onArForceFail*/]);

  const arStatusHandler = (event: any) => {
    const status = event.detail.status;
    if (status === 'failed') {
      console.log('AR FAIL!');
      //AR FAILED
      /*errorRef.current.classList.remove(styles['hide']);
      errorRef.current.addEventListener('transitionend', (event) => {
        errorRef.current.classList.add(styles['hide']);
      });*/
    }
  };

  const onMvLoad = useCallback(() => {
    if (onLoad) {
      onLoad(mvRef.current);
    }
    setLoaded(true);
  }, [onLoad]);

  const onClickPoster = () => {
    if (mvRef.current) {
      mvRef.current.dismissPoster();
    }
  };

  const setVariant = useCallback(() => {
    if (variant) {
      mvRef.current.variantName = variant;
    }
  }, [variant]);

  const pauseAndResetAnimation = () => {
    mvRef.current.pause();
    mvRef.current.animationName = '';
    mvRef.current.currentTime = 0;
    mvRef.current.timeScale = 1;
  };

  const playAnimation = useCallback(() => {
    const anim = mvRef.current.availableAnimations.find((aName: string) => aName === animation);
    const aProps = animationProps;
    if (anim) {
      const options: any = { pingpong: !!aProps?.pingpong };
      if (aProps?.repetitions && aProps.repetitions > 0) {
        options['repetitions'] = aProps.repetitions;
      }
      //pulizia animazione che crea problemi con timeline, repetitions e pingpong
      mvRef.current.pause();
      mvRef.current.animationName = '';
      mvRef.current.updateComplete.then(() => {
        mvRef.current.animationName = anim;
        mvRef.current.setAttribute('animation-crossfade-duration', (aProps?.crossfadeDuration || 0.3) * 1000);
        mvRef.current.updateComplete.then(() => {
          if (aProps?.currentTime && aProps.currentTime > 0) {
            mvRef.current.currentTime = aProps.currentTime;
          } else if (options.repetitions) {
            mvRef.current.currentTime = 0;
          }
          mvRef.current.timeScale = typeof aProps?.timeScale !== 'undefined' ? aProps.timeScale : 1;
          mvRef.current.play(options);
        });
      });
    } else {
      pauseAndResetAnimation();
    }
  }, [animation, animationProps]);

  const animFinished = useCallback(() => {
    //console.log('---animFinished');
    if (typeof onAnimationEnd === 'function') {
      onAnimationEnd();
    }
  }, [onAnimationEnd]);

  const hotspotClick = (hs: Hotspot) => {
    if (typeof onHotspotClick === 'function') {
      onHotspotClick(hs);
    }
  }
  const hotspotMouseEnter = (hs: Hotspot) => {
    if (typeof onHotspotMouseEnter === 'function') {
      onHotspotMouseEnter(hs);
    }
  }
  const hotspotMouseLeave = (hs: Hotspot) => {
    if (typeof onHotspotMouseLeave === 'function') {
      onHotspotMouseLeave(hs);
    }
  }

  const cameraChange = useCallback((evt:Event) => {
    //console.log('---animFinished');
    if (typeof onCameraChange === 'function') {
      onCameraChange(evt);
    }
  }, [onCameraChange]);

  //Effects

  useEffect(() => {
    const mv = mvRef.current;

    if (mv) {
      mv.addEventListener('ar-status', arStatusHandler);
      mv.addEventListener('load', onMvLoad);
      mv.addEventListener('finished', animFinished);
      mv.addEventListener('camera-change', cameraChange);
    }

    setTimeout( //è una sconfitta, ma è l'unico modo per essere sicuri che il componente sia caricato
      () => {
        //setCanAr(mvRef.current.canActivateAR);        
        if (typeof onInit === 'function') {
          onInit(mv);
        }

        if (startArOnInit && mv) {
          openAr(/*true*/);
        }
      },
      250
    );

    return () => {
      if (mv) {
        mv.removeEventListener('ar-status', arStatusHandler);
        mv.removeEventListener('load', onMvLoad);
        mv.removeEventListener('finished', animFinished);
        mv.removeEventListener('camera-change', cameraChange);
      }
    }

  }, [mvRef, startArOnInit, openAr, onInit, onMvLoad, animFinished, cameraChange]);

  useEffect(() => {
    const mv = mvRef.current;
    if (mv && loaded) {
      setVariant();
    }
  }, [setVariant, loaded]);

  useEffect(() => {
    const mv = mvRef.current;
    if (mv && loaded) {
      playAnimation();
    }
  }, [playAnimation, loaded]);

  useEffect(() => {
    const mv = mvRef.current;
    if (mv && cameraView && cameraView.orbit) {
      //calcolo angolo rispetto alla rotazione automatica
      const autorotate = mv.autoRotate;
      mv.autoRotate = false;
      const orbit = getOrbitValues(cameraView.orbit);
      orbit.theta = baseRadiants(baseRadiants(orbit.theta) + baseRadiants(mv.turntableRotation));
      const newOrbit = `${orbit.theta}rad ${baseRadiants(orbit.phi)}rad ${orbit.radius}m`;

      mv.cameraOrbit = newOrbit; //cameraGoTo.orbit;
      mv.cameraTarget = cameraView.target;

      mv.autoRotate = autorotate;
    }
  }, [cameraView]);

  useEffect(() => {
    setLoaded(false);
  }, [src])

  return (
    <>
      <model-viewer key={src} ref={mvRef} class={[styles.mv, !enableHotspot ? styles.hideHotspot : ''].join(' ')} {...attribsCopy} src={src} ios-src={iosSrc}>

        {
          hotspots && hotspots.map((data: Hotspot, idx: number) => <div 
            onClick={() => {
              hotspotClick(data);
              hotspotMouseLeave(data);
            }} 
            onMouseEnter={() => hotspotMouseEnter(data)}
            onMouseLeave={() => hotspotMouseLeave(data)}  
            onTouchStart={() => hotspotMouseEnter(data)}          
            onTouchEnd={() => hotspotMouseLeave(data)}          
            className={['wsv-hotspot', styles.hotspot].join(' ')} 
            key={'hotspot-' + idx} slot={'hotspot-' + idx} 
            data-position={data.position} 
            data-normal={data.normal} 
            data-visibility-attribute="visible" data-visible=""
          >
            <button></button>
            {data.title && <div className='wsv-hotspot-label'>{data.title}</div>}
          </div>)
        }

        { poster &&  <Poster src={poster} label={posterLabel} onClick={onClickPoster} />}

      </model-viewer>
      {loaded && showArBtn && <ButtonIcon onClick={openAr} className='wsv-ui-btn wsv-ar-btn' text={arBtnText} />}
    </>
  );
}