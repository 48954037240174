import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from './app/hooks';

import { Config, MvSharedObj, Hotspot } from './types';

//import logo from './logo.svg';
//import { Counter } from './features/counter/Counter';
import { selectConfig, setConfigAsync } from './features/config/configSlice';

import { setVariants, setSelectedVariant, selectSelectedVariant, selectVariants } from './features/variantSelect/variantSelectSlice';
import { VariantSelect } from './features/variantSelect/VariantSelect';

import { setEnable } from './features/hotspotSwitch/hotspotSwitchSlice';
import { HotspotSwitch } from './features/hotspotSwitch/HotspotSwitch';

import { selectSelectedAnimationSet, setSelectedAnimation } from './features/animationSetSelect/animationSetSelectSlice';
import { AnimationSelect } from './features/animationSetSelect/AnimationSetSelect';

import { selectSelectedCameraView, setSelectedCameraView } from './features/cameraViewSelect/cameraViewSlice';

import { CameraViewSelect } from './features/cameraViewSelect/CameraViewSelect';

import { ModelViewer } from './components/modelviewer/ModelViewer';
import { FullScreenBtn } from './components/fullScreenBtn/FullScreenBtn';
import { Modal } from './components/modal/Modal';
import { QrCode } from './components/qrCode/QrCode';
import { Info } from './components/info/Info';
import { Nav } from './components/nav/Nav';
import { SettingsMenu } from './components/settingsMenu/SettingsMenu';
import { HotspotLabelViewer } from './features/hotspotLabelViewer/HotspotLabelViewer';

import ButtonIcon from './components/buttonIcon/ButtonIcon';
import { Help } from './components/help/Help';

import generateUrl from './utils/generateUrl';
import getUrlParameter from './utils/getUrlParameter';
import getDevice from './utils/getDevice';
import getFileByVersion from './utils/getFileByVersion';
import isAbsolutePath from './utils/isAbsolutePath';

//import 'bootstrap/js/dist/dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import { setLabelSelected } from './features/hotspotLabelViewer/hotspotLabelViewerSlice';

type Props = {
  options?: Config,
  onLoad?: Function,
  onVariant?: Function,
  onView?: Function,
  onMount?: Function,
}

const URL_PARAM_ARSHOW = 'wsvarshow';
const URL_PARAM_VARIANT = 'wsvvariant';
const URL_PARAM_DEBUG = 'debug';

function App({ 
  options,
  onLoad,
  onVariant,
  onView,
  onMount,
}: Props) {

  const dispatch = useAppDispatch();
  const conf = useAppSelector(selectConfig);
  const selectedVariant = useAppSelector(selectSelectedVariant);
  const variants = useAppSelector(selectVariants);
  const selectedAnimation = useAppSelector(selectSelectedAnimationSet);
  const selectedCameraView = useAppSelector(selectSelectedCameraView);  
  const showArUrl = generateUrl(window.location.href, [
    { name: URL_PARAM_ARSHOW, value: '1' },
    { name: URL_PARAM_VARIANT, value: selectedVariant }
  ]);
  const isDebug = !!getUrlParameter(URL_PARAM_DEBUG);

  const [showModal, setShowModal] = useState(false);
  const [forceAr, setForceAr] = useState(false);
  const [urlVariant, setUrlVariant] = useState(getUrlParameter(URL_PARAM_VARIANT) ? getUrlParameter(URL_PARAM_VARIANT) : ''); 
  const [showHelp, setShowHelp] = useState(false);
  const [mvLoaded, setMvLoaded] = useState(false);

  const wrapRef = useRef(null);
  const cameraTORef = useRef(-1);
  const autorotateTORef = useRef(-1);

  //Functions

  const getMvRef = () => {
    return wrapRef.current;
  };

  const arFailed = () => {
    if (conf?.qrcode) {
      setShowModal(true);
    }
  };

  const onModalClosed = () => {
    setShowModal(false);
    setForceAr(false);
    setShowHelp(false);
  };

  const getModalContent = () => {
    if (forceAr) { //la forzatura dell'AR non ha funzionato
      return (<p>{conf?.translation?.arDeviceNotCompatible}</p>);
    } if (showHelp) {
      return <Help />
    } else {
      return (<>
        <QrCode target={showArUrl} />
        <p>{conf?.translation?.qrCodeText}</p>
      </>);
    }
  };

  const handleVariants = (availableVariants: Array<string>, variantDefault: string) => {
    const result = availableVariants;
    dispatch(setVariants(result));
    if (result.length > 0) {
      dispatch(setSelectedVariant(urlVariant.toString() || variantDefault || result[0]));
      if (urlVariant) { //disattivazione selezione variante da URL
        setUrlVariant('');
      }
    }
  };

  const getSharedObj = (mvRef:any, variantDefault:string):MvSharedObj => {
    return {
      modelviewer: mvRef,
      animations: mvRef.availableAnimations,
      variants: mvRef.availableVariants,
      variantDefault: variantDefault,
      cameraViews: conf?.cameraViews,
      playAnimation: (animName:string) => dispatch(setSelectedAnimation(animName)),
      stopAnimation: () => dispatch(setSelectedAnimation('')),
      gotoCameraView: (id:string) => dispatch(setSelectedCameraView(id)),
      setVariant: (variantName:string) => dispatch(setSelectedVariant(variantName)),
    }
  };

  const handleHelp = () => {
    if (showHelp && showModal) {
      setShowModal(false);
      setShowHelp(false);
    } else if (!showHelp) {
      setShowModal(true);
      setShowHelp(true);
    }
  };

  const hotspotClick = (hs:Hotspot) => {
    if (hs.cameraView) {
      dispatch(setSelectedCameraView(hs.cameraView));
    }
  };  
  const hotspotHover = (hs:Hotspot, mouseOut:boolean = false) => {
    if (mouseOut) {
      dispatch(setLabelSelected(""));
    } else {
      dispatch(setLabelSelected(hs.title || ""));
    }
  };

  const getSelectedViewData = useCallback((id:string) => (conf?.cameraViews || []).find((cv:any) => cv.id === id), [conf?.cameraViews]);

  const onMvLoad = (mvRef:any) => {
    let variantDefault = '';
    if (mvRef.availableVariants.length > 0) {
      mvRef.model.materials.forEach((mat: any) => {
        if (mat.isActive) {
          variantDefault = mvRef.availableVariants.find((v: any) => mat.hasVariant(v));
        }
      });
      handleVariants(mvRef.availableVariants || [], variantDefault);
    }

    setForceAr(!!getUrlParameter(URL_PARAM_ARSHOW));

    if (typeof onLoad === 'function') {
      onLoad(getSharedObj(mvRef, variantDefault));
    }

    if (conf?.startAnimation) {
      dispatch(setSelectedAnimation(conf.startAnimation));
    }

    setMvLoaded(true);
  };

  const onCameraChange = useCallback((evt:any) => {
    const mv = evt.target;
    //disattivazione camera view al cambio posizione camera
    if (evt.detail.source !== 'none') {    
      if (cameraTORef.current) {
        window.clearTimeout(cameraTORef.current);
      }
      if (selectedCameraView) {
        const currentView = mv.getCameraOrbit().toString();
        const selectedView = getSelectedViewData(selectedCameraView);
        if (selectedView && currentView !== selectedView.orbit) {
          cameraTORef.current = window.setTimeout(
            () => dispatch(setSelectedCameraView('')),
            150
          );
        }
      }
    }

    //disattivazione camera view alla rotazione automatica
    if (autorotateTORef.current) {
      window.clearTimeout(autorotateTORef.current);
    }
    if (mv.autoRotate) {
      autorotateTORef.current = window.setTimeout(
        () => dispatch(setSelectedCameraView('')),
        mv.autoRotateDelay
      );
    }
  }, [selectedCameraView, dispatch, getSelectedViewData]);

  //Effects

  useEffect(() => {
    if (!conf) {
      dispatch(setConfigAsync({
        url: options && options.assetsPath
          ? options.assetsPath + '/config.json'
          : './assets/config.json',
        override: options
      }));
    } else if (conf?.startCameraView) {
      dispatch(setSelectedCameraView(conf.startCameraView));
    }
  }, [conf, dispatch, options]);

  useEffect(() => {
    dispatch(setEnable(!!conf?.showHotspotOnStart));
  }, [conf?.showHotspotOnStart, dispatch])

  useEffect(() => {
    if (typeof onVariant === 'function' && selectedVariant) {
      onVariant(selectedVariant);
    }
  }, [selectedVariant, onVariant]);

  useEffect(() => {
    if (typeof onView === 'function' && selectedCameraView) {
      const selectedView = getSelectedViewData(selectedCameraView);
      onView(selectedView);
    }
  }, [selectedCameraView, onView, getSelectedViewData]);

  useEffect(() => {
    if (typeof onMount === 'function') {
      onMount();
    }
  }, [onMount]);

  //Logic

  if (!conf) {
    return null;
  }

  const device = getDevice();

  const glbFileVer = getFileByVersion(conf.src || {}, device.osVer);  
  const glbFile =  isAbsolutePath(glbFileVer) ? glbFileVer : conf.assetsPath + '/' + glbFileVer;

  const usdzFileVer = conf.iosSrc ? getFileByVersion(conf.iosSrc, device.osVer) : undefined;
  const usdzFile = usdzFileVer ? isAbsolutePath(usdzFileVer) ? usdzFileVer : conf.assetsPath + '/' + usdzFileVer : undefined;

  const posterFile = conf.poster ? isAbsolutePath(conf.poster) ? conf.poster : conf.assetsPath + '/' + conf.poster : '';

  const mvPropsArr = [];
  Object.keys(conf.props || {}).forEach(key => {
    if (conf && conf.props) {
      mvPropsArr.push({
        key,
        value: typeof conf.props[key] === 'object' ? JSON.stringify(conf.props[key]) : conf.props[key],
      });
    }
  });
  mvPropsArr.push({
    key: 'variants',
    value: JSON.stringify(variants),
  });

  const cameraView = getSelectedViewData(selectedCameraView);

  const settingsElements = [];
  if (conf.showSettings) {
    if (conf.showSettingsHotspot && conf.hotspots && conf.hotspots.length > 0) {
      settingsElements.push(<HotspotSwitch key={'settings-el-' + conf.hotspots.length} text={conf.translation?.settingsHotspot} />);
    }
  }

  const animData = (conf.animationSets || []).find((a:any) => a.id === selectedAnimation);
  
  return (
    <div ref={wrapRef} className={['wsv-app-wrapper', conf.hotspotMediaQuery ? 'wsv-prop-hotspot-hover' : ''].join(' ')}>
      <ModelViewer
        onLoad={onMvLoad}
        onHotspotClick={hotspotClick}
        onHotspotMouseEnter={(hs:Hotspot) => hotspotHover(hs)}   
        onHotspotMouseLeave={(hs:Hotspot) => hotspotHover(hs, true)}     
        onArFail={arFailed}
        onCameraChange={onCameraChange}
        hotspots={conf.hotspots}
        attributes={conf.props}
        src={glbFile}
        iosSrc={usdzFile}
        showArBtn={conf.showArBtn}
        arBtnText={conf.translation?.arButton}
        startArOnInit={forceAr && conf.quickAr}
        variant={selectedVariant}
        animation={animData?.idAnimation}
        animationProps={animData}
        cameraView={cameraView}
        poster={posterFile}
        posterLabel={conf.posterButton ? conf.translation?.posterButton : ''}
      />

      { mvLoaded && <Nav>
        { conf.showCameraViewSelect && <CameraViewSelect /> }

        { conf.showAnimationSelect && <AnimationSelect /> }

        {
          settingsElements.length > 0 && <SettingsMenu>
            {settingsElements.map(el => el)}
          </SettingsMenu>
        }

        {conf.fullscreen && !device.isMobile && <FullScreenBtn target={getMvRef} />}   

        {conf.showHelp && <ButtonIcon className='wsv-ui-btn wsv-help-btn' onClick={handleHelp} />}     
      </Nav> }

      { mvLoaded && <VariantSelect /> }    

      <HotspotLabelViewer />

      <Modal className={showHelp ? 'wsv-modal-body-help' : ''} show={showModal} onClosed={onModalClosed} size={!showHelp ? 'sm' : 'md'}>
        {getModalContent()}
      </Modal>      

      {isDebug && <Info mvProps={mvPropsArr} glb={glbFile} usdz={usdzFile} />}
    </div>
  );
}

export default App;
