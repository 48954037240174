import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState/*, AppThunk*/ } from '../../app/store';

export interface HotspotSwitchState {
    enable: boolean;
}

const initialState: HotspotSwitchState = {
    enable: true,
};

export const hotspotSwitchSlice = createSlice({
    name: 'hotspotSwitch',
    initialState,
    reducers: {
        setEnable: (state, action:PayloadAction<boolean>) => {
            state.enable = action.payload;
        }
    }
});

export const { setEnable } = hotspotSwitchSlice.actions;

export const selectEnable = (state: RootState) => state.hotspotSwitch.enable;

export default hotspotSwitchSlice.reducer;