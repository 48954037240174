/**
 * Converte i radianti nell'intervallo 0-2PI
 * @param {number} radiants 
 * @returns {number}
 */
const baseRadiants = (radiants: number):number => {        
    const value = Math.abs(radiants) % (2 * Math.PI);
    return radiants < 0 ? value * -1 : value;
};

export default baseRadiants;