import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { selectSelectedVariant, selectVariants, setSelectedVariant } from './variantSelectSlice';

import sanitizeString from '../../utils/sanitizeString';

type Props = {
    className?: string,
}

export function VariantSelect({ className }: Props) {
    const dispatch = useAppDispatch();
    const selected = useAppSelector(selectSelectedVariant);
    const variants = useAppSelector(selectVariants);

    const [opened, setOpened] = useState(false);

    const onChange = (value:string) => {
        dispatch(setSelectedVariant(value));
    }

    if (variants.length === 0) {
        return null;
    }

    return (
        <div className={['wsv-variant-select d-flex flex-row', opened ? 'opened' : ''].join(' ')}>
            <button className='wsv-vs-btn' onClick={() => setOpened(!opened)}>open</button>
            <div className='wsv-vs-container d-flex align-items-center justify-content-end'>
                <div className='wsv-vs-list d-flex flex-row'>
                    {
                        variants.map((v: any, idx: number) => <div key={idx} onClick={ () => onChange(v) } className={['wsv-vs-list-item', 'wsv-vs-list-item-' + idx, 'wsv-vs-list-item-' + sanitizeString(v), v === selected ? 'active' : ''].join(' ')}>
                            <span className='swatch'></span>
                            <span className='description'>{ v }</span>
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
}