// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModelViewer_mv__QL7CY{position:absolute;top:0;left:0;right:0;bottom:0;width:100%;height:100%}model-viewer::part(default-ar-button){display:none}.ModelViewer_hideHotspot__mQRIi .ModelViewer_hotspot__szfkW{display:none !important}\n", "",{"version":3,"sources":["webpack://./src/components/modelviewer/ModelViewer.module.scss"],"names":[],"mappings":"AAAA,uBACI,iBAAkB,CAClB,KAAK,CAAE,MAAM,CAAE,OAAQ,CAAE,QAAS,CAClC,UAAW,CAAE,WAAY,CAC5B,sCAGG,YAAa,CAChB,4DAIO,uBAAuB","sourcesContent":[".mv {\r\n    position: absolute;\r\n    top:0; left:0; right: 0; bottom: 0;\r\n    width: 100%; height: 100%;\r\n}\r\n\r\nmodel-viewer::part(default-ar-button) {\r\n    display: none;\r\n}\r\n\r\n.hideHotspot {\r\n    .hotspot {\r\n        display: none!important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mv": "ModelViewer_mv__QL7CY",
	"hideHotspot": "ModelViewer_hideHotspot__mQRIi",
	"hotspot": "ModelViewer_hotspot__szfkW"
};
export default ___CSS_LOADER_EXPORT___;
