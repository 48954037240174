import { GenericObject } from '../types';

export default function getFileByVersion(files:GenericObject, version:number|string):string {
    if (files.hasOwnProperty(version)) {
        return files[version];
    } else if (files.hasOwnProperty('default')) {
        return files.default;
    }

    return '';
}