import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState/*, AppThunk*/ } from '../../app/store';

export interface CameraViewSelectState {
    selected: string;
}

const initialState: CameraViewSelectState = {
    selected: '',
};

export const cameraViewSelectSlice = createSlice({
    name: 'cameraViewSelectSlice',
    initialState,
    reducers: {
        setSelectedCameraView: (state, action:PayloadAction<string>) => {
            state.selected = action.payload;
        },
    }
});

export const { setSelectedCameraView } = cameraViewSelectSlice.actions;

export const selectSelectedCameraView = (state: RootState) => state.cameraViewSelect.selected;

export default cameraViewSelectSlice.reducer;