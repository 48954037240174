import axios from 'axios';

export function fetchConfig(url:string) {
    return new Promise<{ data: any }>(async (resolve, reject) => {
        try {
            const response = await axios.get(url);
            let data = null;
            //return issuesResponse.data;
            if (response.headers['content-type'].indexOf('application/json') > -1) {
                data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
            }

            resolve({ data });
        } catch (err) {
            reject(err);
        }
    });
}
