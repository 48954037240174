// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Poster_poster__RX\\+RJ{position:absolute;left:0;right:0;top:0;bottom:0;background-size:contain;background-repeat:no-repeat;background-position:center}\n", "",{"version":3,"sources":["webpack://./src/components/modelviewer/components/poster/Poster.module.scss"],"names":[],"mappings":"AAAA,uBACI,iBAAkB,CAClB,MAAO,CACP,OAAQ,CACR,KAAM,CACN,QAAS,CAET,uBAAwB,CACxB,2BAA4B,CAC5B,0BAA2B","sourcesContent":[".poster {\r\n    position: absolute;\r\n    left: 0;\r\n    right: 0;\r\n    top: 0;\r\n    bottom: 0;\r\n    //background-image: url(\"../../assets/poster-damagedhelmet.webp\");\r\n    background-size: contain;\r\n    background-repeat: no-repeat;\r\n    background-position: center;\r\n}\r\n.posterBtn {\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"poster": "Poster_poster__RX+RJ"
};
export default ___CSS_LOADER_EXPORT___;
