import React from 'react';

import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';

import "./settingsMenu.scss";

type Props = {
    className?: string,
    children?: any,
}

export function SettingsMenu({ className, children }: Props) {

    return (
        <DropdownButton
            as={ButtonGroup}          
            variant="none"
            className={["wsv-ui-btn wsv-settings-btn", className].join(' ')}
            id="wsv-menu-settings"
            drop="up"            
            title={<span className='wsv-btn-icon'></span>}
            autoClose="outside"
            menuVariant="dark"
        >
            { React.Children.map(children, (child: React.ReactElement<any>, idx: number) => <Dropdown.ItemText key={'item-' + idx} className='dropdown-item'>{ child }</Dropdown.ItemText>) }
        </DropdownButton>
    );
}
