import { GenericObject } from '../types';

type UrlParams = {
    name: string,
    value: string
}

export default function generateUrl(url:string, params:Array<UrlParams>|GenericObject):string {
    const returnUrl = new URL(url);
    if (params) {
        let data = Array.isArray(params) ? params : Object.keys(params).map(key => {
            return { name: key, value: params[key] };
        });
        data.forEach(param => {
            returnUrl.searchParams.delete(param.name);
            returnUrl.searchParams.append(param.name, param.value);
        });
    }
    return returnUrl.href;
}