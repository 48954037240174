import React, { useState, useEffect } from 'react';

import QRCode from 'qrcode';

//import styles from './QrCode.module.scss';

type Props = {
    target: string,
    className?: string,
}

export function QrCode({
    className,
    target
}: Props) {
    const [qrCodeStr, setQrCodeStr] = useState('');

    //console.log({baseUrl, params});

    const generateQR = async (text: string) => {
        try {
            let result = await QRCode.toDataURL(text);
            setQrCodeStr(result);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        generateQR(target);
    }, [target]);

    return (
        <img src={qrCodeStr} className={['wsv-qrcode', className].join(' ')} alt="" />
    );
}
