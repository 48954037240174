// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputSwitch_root__4EMWx label{-webkit-user-select:none;-ms-user-select:none;user-select:none}\n", "",{"version":3,"sources":["webpack://./src/components/inputSwitch/InputSwitch.module.scss"],"names":[],"mappings":"AAAA,+BAEQ,wBAAA,CAAA,oBAAA,CAAA,gBAAiB","sourcesContent":[".root {\r\n    label {\r\n        user-select: none;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "InputSwitch_root__4EMWx"
};
export default ___CSS_LOADER_EXPORT___;
