
import styles from './Help.module.scss';

type Props = {
    className?: string,
}

export function Help({ className }: Props) {
    return (
        <div className={["d-flex wsv-help-box align-items-stretch", styles.root, className].join(' ')}>
            <div className="wsv-help-box-content"></div>
        </div>
    );
}
