import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState/*, AppThunk*/ } from '../../app/store';

export interface VariantSelectState {
    variants: Array<string>;
    selected: string;
}

const initialState: VariantSelectState = {
    variants: [],
    selected: '',
};

export const variantSelectSlice = createSlice({
    name: 'variantSelect',
    initialState,
    reducers: {
        setVariants: (state, action:PayloadAction<Array<string>>) => {
            state.variants = action.payload;
        },
        setSelectedVariant: (state, action:PayloadAction<string>) => {
            state.selected = action.payload;
        },
    }
});

export const { setVariants, setSelectedVariant } = variantSelectSlice.actions;

export const selectVariants = (state: RootState) => state.variantSelect.variants;
export const selectSelectedVariant = (state: RootState) => state.variantSelect.selected;

export default variantSelectSlice.reducer;