import React, { useState, useEffect } from 'react';

import styles from './Poster.module.scss';

type Props = {
    src: string,
    label?: string,
    onClick?: Function,
}

export function Poster({
    onClick,
    src,
    label,
}: Props) {

    //functions

    const onClickPoster = () => {
        if (typeof onClick === 'function') {
            onClick();
        }
    };

    return (
        <>
            <div 
                slot="poster"
                className={[styles.poster, 'wsv-poster'].join(' ')}
                style={{backgroundImage: 'url(' + src + ')' }}
                onClick={!label ? onClickPoster : () => {}}
            />
            { label && <div 
                    slot="poster"
                    className={[styles.posterBtn, 'wsv-btn', 'wsv-ui-btn', 'wsv-poster-btn'].join(' ')}
                    onClick={onClickPoster}
                >
                    <span className="wsv-poster-btn-label">{ label }</span>
                </div>
            }
        </>
    );
}