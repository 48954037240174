
import { useAppSelector } from '../../app/hooks';
import { selectConfig } from '../config/configSlice';
import styles from './HotspotLabelViewer.module.scss';
import { selectLabelSelected } from './hotspotLabelViewerSlice';

type Props = {
    className?: string,
    selected?: string,
    children?: any,
}

export function HotspotLabelViewer({ className }: Props) {
    const conf = useAppSelector(selectConfig);
    const selected = useAppSelector(selectLabelSelected);

    if (!conf || !conf.hotspots || conf.hotspots.length === 0 || !selected) {
        return null;
    }

    return (
        <div className={["wsv-hotspot-label-viewer", className].join(' ')}>
            <div className='wsv-hotspot-label'>{selected}</div>
        </div>
    );
}
