import { GenericObject } from '../types';

type ResultObject = {
    found: boolean,
    auth: boolean,
    data: any,
}

const xhrCache:GenericObject = {};

/**
 * Dice se il file è raggiungibile e se è protetto da autorizzazione
 * @param {string} fileUrl 
 * @returns 
 */
const fileCheck = (fileUrl: string, noCache?: boolean):ResultObject => {
    const result:ResultObject = {
        found: false,
        auth: false,
        data: null,
    };

    if (!noCache && xhrCache[fileUrl]) {
        //console.log('[fileCheck]: ' + fileUrl + ' result from cache');
        return xhrCache[fileUrl];
    }

    const xhr = new XMLHttpRequest();
    try {
        xhr.open('HEAD', fileUrl, false);
        xhr.setRequestHeader("Authorization", ""); //rimuove l'auth dalla request per vedere se i file sono raggiungibili senza auth, causerà prompt di auth per ogni chiamata
        xhr.send();
    } catch (e) {
        console.error('[fileCheck]: XMLHttpRequest Failed', { fileUrl, error: e });
        result.data = {
            responseURL: fileUrl
        }
        return result;
    }

    //console.log('---', xhr);

    result.data = xhr;

    if (xhr.status !== 404) {
        result.found = true;
    }
    if (xhr.status === 401) {
        result.auth = true;
    }

    xhrCache[fileUrl] = result;

    return result;
}

export default fileCheck;