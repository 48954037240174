import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState/*, AppThunk*/ } from '../../app/store';

export interface AniamtionSelectState {
    selected: string;
}

const initialState: AniamtionSelectState = {
    selected: '',
};

export const animationSetSelectSlice = createSlice({
    name: 'animationSetSelect',
    initialState,
    reducers: {
        setSelectedAnimation: (state, action:PayloadAction<string>) => {
            state.selected = action.payload;
        },
    }
});

export const { setSelectedAnimation } = animationSetSelectSlice.actions;

export const selectSelectedAnimationSet = (state: RootState) => state.animationSetSelect.selected;

export default animationSetSelectSlice.reducer;