import React, { useState, useEffect } from 'react';

import styles from './Modal.module.scss';

type Props = {
    className?:string,
    children?:any,
    onClosed?:Function,
    show?:boolean,
    size?:'sm' | 'md' | 'lg',
}

export function Modal({
    className,
    children,
    onClosed,
    show,
    size,
}: Props) {
    const [isShow, setIsShow] = useState(show);

    //function

    const stopPropagation = (evt:any) => {
        evt.preventDefault();
        evt.stopPropagation();
    };

    const close = () => {
        setIsShow(false);
        if (typeof onClosed === 'function') {
            onClosed();
        }
    }

    const getSize = () => {
        switch(size) {
            case 'sm':
                return styles.sm;
            case 'lg':
                return styles.lg;
            default:
                return styles.md;
        }
    };

    //effect

    useEffect(() => {
        setIsShow(show);
    }, [show]);

    return (
        <div className={['wsv-modal', className, isShow ? styles.show : '', styles.wsvModal].join(' ') } onClick={close}>
            <div className={['wsv-modal-dialog', styles.modalDialog, getSize(), styles.modalDialogCentered].join(' ')} onClick={stopPropagation}>
                <div className={['wsv-modal-content', styles.modalContent].join(' ')}>
                    <div className={['wsv-modal-header', styles.modalHeader].join(' ')}>
                        <button onClick={close} className={['wsv-modal-close', styles.modalClose].join(' ')}></button>
                    </div>
                    <div className={['wsv-modal-body', styles.modalBody].join(' ')}>
                        { children }
                    </div>
                </div>
            </div>
        </div>
    );
}
