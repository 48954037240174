import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

import { Config, AnimationOptions, MvSharedObj } from './types';

class AppApi {
    private container:any;
    private root:any;
    private options:Config;
    private selector:string;
    private mvShared:MvSharedObj | null;
    private animCallbacks:Array<Function>;

    constructor(selector:string, options:Config) {
      this.selector = selector;
      this.options = options;
      this.animCallbacks = [];
      this.mvShared = null;
    }

    //private

    _onMvLoad(mvObj:MvSharedObj) {
      this.mvShared = mvObj;

      this.mvShared.modelviewer.addEventListener('finished', () => this._animFinished());

      if (typeof this.options.onLoad === 'function') {
        this.options.onLoad();
      }
    }

    _animFinished() {      
      while (this.animCallbacks.length > 0) {
        const cb = this.animCallbacks.shift();
        if (typeof cb === 'function') {
          cb();
        }
      }
    }

    //public

    render():Promise<any> {
      return new Promise((resolve) => {
        this.container = document.querySelector(this.selector)!;
        this.root = createRoot(this.container);
        
        const optionsClear = Object.assign({},
          this.options,
          { //rimozione elementi non serializzabili in redux
            onVariant: null,
            onView: null,
            onLoad: null,
          }
        );

        this.root.render(
          //<React.StrictMode>
            <Provider store={store}>
              <App 
                options={optionsClear}
                onMount={() => resolve(true)}
                onLoad={(mvShared:any) => {
                  this._onMvLoad(mvShared);                  
                }} 
                onVariant={this.options.onVariant}
                onView={this.options.onView}
              />
            </Provider>
          //</React.StrictMode>
        );
        
        // If you want to start measuring performance in your app, pass a function
        // to log results (for example: reportWebVitals(console.log))
        // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
        reportWebVitals();
      });
    }

    getVersion():string | undefined {
      return process.env.REACT_APP_VERSION;
    }

    getVariants() {
      return this.mvShared?.variants;
    }

    setVariant(variantName:string) {
      this.mvShared?.setVariant(variantName);
    }

    getAnimations() {
      return this.mvShared?.animations;
    }
    
    playAnimation(animationName:string, options:AnimationOptions) {
      if (options && options.onEnd) {
        this.animCallbacks.push(options.onEnd);
      }
      this.mvShared?.playAnimation(animationName);
    }

    stopAnimation() {
      this.mvShared?.stopAnimation();
    }

    getCameraViews() {
      return this.mvShared?.cameraViews;
    }
    gotoCameraView(id:string) {
      this.mvShared?.gotoCameraView(id);
    }
}

export default AppApi;