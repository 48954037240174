import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
//import counterReducer from '../features/counter/counterSlice';
import configReducer from '../features/config/configSlice';
import hotspotSwitchReducer from '../features/hotspotSwitch/hotspotSwitchSlice';
import variantSelectReducer from '../features/variantSelect/variantSelectSlice';
import animationSetSelectReducer from '../features/animationSetSelect/animationSetSelectSlice';
import cameraViewSelectReducer from '../features/cameraViewSelect/cameraViewSlice';
import hotspotLabelViewerSlice from '../features/hotspotLabelViewer/hotspotLabelViewerSlice';

export const store = configureStore({
  reducer: {
    //counter: counterReducer,
    config: configReducer,
    hotspotSwitch: hotspotSwitchReducer,
    variantSelect: variantSelectReducer,
    animationSetSelect: animationSetSelectReducer,
    cameraViewSelect: cameraViewSelectReducer,
    hotspotLabelViewer: hotspotLabelViewerSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
