import React from 'react';

type Props = {
    className?: string,
    children?: any,
}

export function Nav({ className, children }: Props) {
    return (
        <div className={ ['wsv-nav', className].join(' ') }>
            { React.Children.map(children, (child: React.ReactElement<any>) => child ? <div className='wsv-nav-item'>{ child }</div> : null) }
        </div>
    );
}
