import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
//import { url } from 'inspector';
import { RootState/*, AppThunk*/ } from '../../app/store';
import mergeWith from 'lodash.mergewith';

import { fetchConfig } from './configApi';

import { /*Translation,*/ Config } from '../../types';
export interface ConfigState {
    data: Config | undefined;
    status: 'idle' | 'loading' | 'failed';
    version: string | undefined;
}

const initialState: ConfigState = {
    data: undefined,    
    status: 'idle',
    version: process.env.REACT_APP_VERSION,
};

const mergeCustomize = (objValue: any, srcValue: any) => {
    if (Array.isArray(srcValue) && srcValue.length === 0) { //override con array vuoto
        return srcValue;
    } else if (typeof srcValue === 'object' && !Array.isArray(srcValue) && srcValue !== null && Object.keys(srcValue).length === 0) { //override con oggetto vuoto
        return srcValue;
    }
};

export const setConfigAsync = createAsyncThunk(
    'config/fetchConfig',
    async (params: any) => {
        const { url, override } = params;
        const response = await fetchConfig(url);
        // The value we return becomes the `fulfilled` action payload
        let data = response.data;
        if (override) {
            data = mergeWith({}, data, override, mergeCustomize);
        }
        return data;
    }
);

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setConfig: (state, action:PayloadAction<any>) => {
            state.data = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setConfigAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(setConfigAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload;
            })
            .addCase(setConfigAsync.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { setConfig } = configSlice.actions;

export const selectConfig = (state: RootState) => state.config.data;

export default configSlice.reducer;