import React from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { selectSelectedCameraView, setSelectedCameraView } from './cameraViewSlice';

import { selectConfig } from '../config/configSlice';

type Props = {
    className?: string,
}

export function CameraViewSelect({ className }: Props) {
    const dispatch = useAppDispatch();
    const selected = useAppSelector(selectSelectedCameraView);
    const config = useAppSelector(selectConfig);
    const items = (config?.cameraViews || []).filter((item:any) => item.enabled).sort((a:any,b:any) => a.order - b.order); //useAppSelector(selectAnimationSets);    

    const onChange = (event: any) => {
        dispatch(setSelectedCameraView(event.currentTarget.value));
    }

    if (items.length === 0) {
        return null;
    }

    return (
        <div className={['wsv-animation-select d-flex flex-row', className].join(' ')}>
            <select onChange={onChange} value={selected}>
                <option value="">{ config?.translation?.noCameraViewLabel }</option>
                {
                    items.map((item:any, idx:number) => <option key={idx} value={item.id}>{ item.title }</option>)
                }
            </select>
        </div>
    );
}