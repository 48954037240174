import { GenericObject } from "../../../types";

/**
 * Converte le coordinate della camera in valori
 * @param {string} dataStr 
 * @returns 
 */
const getOrbitValues = (dataStr:String):GenericObject => {
    const result = {
        theta: 0,
        phi: 0,
        radius: 0,
    };

    if (!dataStr) {
        return result;
    }

    const regex = new RegExp('([0-9.-]+)', 'gm');
    const matches = [...dataStr.matchAll(regex)];

    if (matches.length === 3) {
        result.theta = parseFloat(matches[0][0]);
        result.phi = parseFloat(matches[1][0]);
        result.radius = parseFloat(matches[2][0]);
    }

    return result;
}

export default getOrbitValues;