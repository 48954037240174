import React from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { selectSelectedAnimationSet, setSelectedAnimation } from './animationSetSelectSlice';

//import sanitizeString from '../../utils/sanitizeString';
import { selectConfig } from '../config/configSlice';

type Props = {
    className?: string,
}

export function AnimationSelect({ className }: Props) {
    const dispatch = useAppDispatch();
    const selected = useAppSelector(selectSelectedAnimationSet);
    const config = useAppSelector(selectConfig);
    const animations = (config?.animationSets || []).filter((as:any) => as.enabled).sort((a:any,b:any) => a.order - b.order); //useAppSelector(selectAnimationSets);    

    const onChange = (event: any) => {
        dispatch(setSelectedAnimation(event.currentTarget.value));
    }

    if (animations.length === 0) {
        return null;
    }

    return (
        <div className={['wsv-animation-select d-flex flex-row', className].join(' ')}>
            <select onChange={onChange} value={selected}>
                <option value="">{ config?.translation?.noAnimationLabel }</option>
                { /*animations.map((aName: string, idx: number) => {
                    const confData = (config?.animationSets || []).find((ca: any) => ca.idAnimation === aName) || {};
                    return <option key={idx} value={aName}>{confData.title !== '' ? confData.title : aName}</option>;
                })*/}
                {
                    animations.map((as:any, idx:number) => <option key={idx} value={as.id}>{ as.title }</option>)
                }
            </select>
        </div>
    );
}